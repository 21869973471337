<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row>

            <!--            <b-col-->
            <!--              cols='12'-->
            <!--            >-->
            <!--              <validation-provider-->
            <!--                v-slot='{ errors }'-->
            <!--                :name="$t('name')"-->
            <!--                vid='name'-->
            <!--                rules='required'-->
            <!--              >-->
            <!--                <b-form-group-->
            <!--                  :label="$t('name')"-->
            <!--                >-->
            <!--                  <b-form-input-->
            <!--                    v-model='form.name'-->
            <!--                    :placeholder="$t('name')"-->
            <!--                    autocomplete='off'-->
            <!--                    type='text'-->
            <!--                  />-->
            <!--                  <small class='text-danger'>{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </b-col>-->

            <!--            <b-col-->
            <!--              cols='12'-->
            <!--            >-->
            <!--              <validation-provider-->
            <!--                v-slot='{ errors }'-->
            <!--                :name="$t('description')"-->
            <!--                vid='description'-->
            <!--                rules='required'-->
            <!--              >-->
            <!--                <b-form-group-->
            <!--                  :label="$t('description')"-->
            <!--                >-->
            <!--                  <b-form-textarea-->
            <!--                    v-model='form.description'-->
            <!--                    :placeholder="$t('description')"-->
            <!--                    autocomplete='off'-->
            <!--                    rows='5'-->
            <!--                  />-->
            <!--                  <small class='text-danger'>{{ errors[0] }}</small>-->
            <!--                </b-form-group>-->
            <!--              </validation-provider>-->
            <!--            </b-col>-->


            <b-col
              cols='12'
              md='6'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='types'
                :name="$t('type')"
              >
                <b-form-group
                  :label="$t('type')"
                >
                  <v-select
                    v-model='form.type'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='types'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"

                    @input="inputChange('type')"

                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col
              cols='12'
              md='6'
              v-if='form.type =="2"'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='link'
                :name="$t('link')"
                rules='required'
              >
                <b-form-group
                  :label="$t('link')"
                >
                  <v-select
                    v-model='form.link'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='pages'
                    :reduce='item => item.id'
                    :placeholder="$t('select')"
                  >
                    <template #no-options>
                      {{ $t('No options') }}
                    </template>
                  </v-select>
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
              md='6'
              v-if='form.type == "3"'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('link')"
                vid='link'
                rules='required'
              >
                <b-form-group
                  :label="$t('link')"
                >
                  <b-form-input
                    v-model='form.link'
                    :placeholder="$t('link')"
                    autocomplete='off'
                    type='text'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='image'
                :name="$t('labels.image')"
              >
                <ImagePreview
                  v-model='image'
                  :label="$t('labels.image')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
              <hr class='my-1'>
            </b-col>


            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'

export default {
  mixins: [formMixin],
  data() {
    return {
      image: null,
      types: [{
        id: 1,
        name: this.$t('without_link'),
      },
        {
          id: 2,
          name: this.$t('internal_link'),
        },
        {
          id: 3,
          name: this.$t('external_link'),
        },
      ],

      pages: [
        {
          id: '/subscriptions',
          name: this.$t('packages'),
        },
        {
          id: '/profile',
          name: this.$t('Profile'),
        },
      ],
      form: {
        type: null,
        // name: null,
        // description: null,
        image: null,
        link: null,
      },

      isDataLoaded: false,
    }
  },
  created() {

    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },

     

  },

  methods: {
    inputChange(type) {
      if (type == 'type') {
          this.form.link=null;
      }

    },

    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            type: data.type,
            link: data.link,
          }
          this.image = data.image_path

          this.isDataLoaded = true
        })
    },
  },

}
</script>
